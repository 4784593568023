<script setup></script>
<template>
  <div class="header-wrapper">
    <div class="logo-wrap">
      <img src="@/assets/images/milogo.png" alt="" />
    </div>
    <div v-show="false" class="search-wrap">
      <img src="@/assets/images/searchicon.svg" alt="" />
    </div>
  </div>
</template>
<style scoped lang="scss">
.header-wrapper {
  width: 100%;
  height: 125px;
  // box-shadow: 0px 16px 27px 4px rgba(0, 0, 0, 0.16);
  // background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;

  .logo-wrap {
    margin-left: 20px;
  }
}

@media only screen and (max-width: 748px) {
  .header-wrapper {
    flex-direction: row-reverse;
    padding: 0 25px;
    .logo-wrap {
      height: 50px;
      width: 50px;

      img {
        height: 100%;
      }
    }
  }
}
</style>
