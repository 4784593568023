<script setup>
import { onMounted, onUpdated, computed } from 'vue';

const props = defineProps({
  tableHeaders: Array,
  tableRows: Array,
  activeTab: Number,
});

const allDownhillFromHere = () => {
  const deletables = document.querySelectorAll('.deleteMe');
  deletables.forEach((deletable) => {
    deletable.remove();
  });
};

onMounted(() => {
  allDownhillFromHere();
});

onUpdated(() => {
  allDownhillFromHere();
});

const isMobile = computed(() => {
  return window.innerWidth <= 748;
});

const fontSizeChanger = (rowIndex, colIndex) => {
  if (props.activeTab === 2) {
    if (isMobile.value) {
      switch (rowIndex) {
        case 3:
          return 11;
        case 7:
          return 10;
        default:
          return 19;
      }
    } else {
      switch (rowIndex) {
        case 3:
          return 20;
        case 7:
          return 17;
        default:
          return 35;
      }
    }
  }

  if (props.activeTab === 3) {
    if (isMobile.value) {
      switch (rowIndex) {
        case 6:
          if (colIndex === 0 || colIndex === 1) return 12;
          return 13;
        case 8:
          if (colIndex === 2 || colIndex === 4) return 12;
          return 13;
        case 12:
          return 5;
        default:
          return 13;
      }
    } else {
      switch (rowIndex) {
        case 6:
          if (colIndex === 0 || colIndex === 1) return 29;
          return 32;
        case 8:
          if (colIndex === 2 || colIndex === 4) return 29;
          return 32;
        case 12:
          return 12;
        default:
          return 32;
      }
    }
  }

  if (props.activeTab === 4) {
    if (isMobile.value) {
      switch (rowIndex) {
        case 5:
        case 6:
        case 9:
        case 10:
          return 13;
        case 7:
          return 11;
        default:
          return 6;
      }
    } else {
      switch (rowIndex) {
        case 5:
        case 6:
        case 9:
        case 10:
          return 32;
        case 7:
          return 27;
        default:
          return 15;
      }
    }
  }

  if (props.activeTab === 5) {
    if (isMobile.value) {
      switch (rowIndex) {
        case 0:
        case 7:
        case 8:
          return 10;
        default:
          return 22;
      }
    } else {
      switch (rowIndex) {
        case 0:
        case 7:
        case 8:
          return 20;
        default:
          return 44;
      }
    }
  }
};

// const nastyyyy = (rowIndex, colIndex) => {
//   if (props.activeTab === 1) {
//     if (rowIndex === 0 || rowIndex === 2 || rowIndex === 3) {
//       if (colIndex % 2 === 1) return true
//     } else {
//       if (colIndex < 9 && colIndex % 2 === 1) {
//         return true
//       }
//       // if ((rowIndex === 1 && colIndex === 8) || (rowIndex === 4 && colIndex === 8)) {
//       if (colIndex === 8 && (rowIndex === 1 || rowIndex === 4)) {
//         return true
//       }
//     }
//   }

//   if (props.activeTab === 2 || props.activeTab === 5) {
//     if (colIndex % 2 === 1) return true
//   }

//   if (props.activeTab === 3) {
//     if (rowIndex === 2 || rowIndex === 3 || rowIndex === 4) {
//       if (colIndex % 2 === 1) return true
//     } else {
//       if (colIndex < 6) {
//         if (colIndex % 2 === 1) return true
//       } else {
//         if (colIndex === 7 || colIndex === 8 || colIndex === 10 || colIndex === 12) {
//           return true
//         }
//       }
//     }
//   }

//   if (props.activeTab === 4) {
//     if (rowIndex === 0 || rowIndex === 1 || rowIndex === 4) {
//       if (colIndex % 2 === 1) return true
//     } else {
//       if (colIndex < 9 && colIndex % 2 === 1) {
//         return true
//       }
//       if ((rowIndex === 2 || rowIndex === 3) && colIndex === 10) {
//         return true
//       }
//     }
//   }
//   return false
// }
</script>
<template>
  <div class="table-wrapper">
    <table ref="myTable" class="table">
      <thead>
        <tr class="row">
          <th></th>
          <!-- <th
            v-for="(header, idkanymore) of tableHeaders"
            :key="idkanymore"
            style="white-space: pre"
            :class="{ 'alt-bg': idkanymore % 2 === 0 }"
          >
            {{ header }}
          </th> -->
          <th
            v-for="(image, index) in tableRows"
            :key="index"
            :class="{
              skuter: activeTab === 1,
              cistac: activeTab === 2,
              roomba: activeTab === 3,
              sat: activeTab === 4,
              sluske: activeTab === 5
            }"
          >
            <a :href="image[0].link" target="_blank">
              <img :src="image[0].imgSrc" alt="" />
            </a>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- <tr v-for="(row, i) in tableRows" :key="i">
          <td
            v-for="(cell, j) in row.filter((cell) => cell !== '*')"
            ref="tableCells"
            :key="j"
            :rowspan="cell?.mergeDown ? (cell.rowspan ? cell.rowspan : 2) : 1"
            :colspan="cell?.mergeRight ? (cell.colspan ? cell.colspan : 2) : 1"
            :style="{ fontSize: fontSizeChanger(j, row) + 'px' }"
            :class="{ 'alt-bg': nastyyyy(i, j), disgust: activeTab === 2 && j === 8 }"
          >
            <a v-if="j === 0" :href="cell.link" target="_blank">
              <img :src="cell.imgSrc" alt="" />
              <img v-if="!isMobile" :src="cell.imgSrc[0]" alt="" />
              <img v-else :src="cell.imgSrc[1]" alt="" />
            </a>
            <div
              v-else
              class="content"
              :class="{ 'idk-anymore': activeTab === 4 && j !== 3 && j !== 1 }"
            >
              <div v-if="typeof cell !== 'object' && String(cell).includes('png')">
                <img :src="cell" alt="" />
              </div>
              <div v-else-if="cell?.mergeDown || cell?.mergeRight">
                <div v-if="typeof cell.icon === 'object'" class="multiple-img">
                  <img v-for="(pic, l) of cell.icon" :key="l" :src="pic" alt="" />
                </div>
                <img v-else-if="cell?.mergeDown" :src="cell.icon" alt="" />
                {{ cell?.mergeDown ? '' : cell?.mergeRight ? cell.content : cell }}
              </div>
              <div v-else-if="typeof cell === 'object'" class="content-content">
                <span v-for="(item, k) in cell" :key="k">
                  {{ item }}
                </span>
              </div>
              <span v-else>
                {{ cell }}
              </span>
            </div>
          </td>
        </tr> -->
        <tr v-for="(header, i) in tableHeaders" :key="i">
          <td :class="{ 'alt-bg': i % 2 === 0 }" class="header">
            {{ header }}
          </td>
          <td
            v-for="(tableCol, j) in tableRows"
            :key="j"
            :colspan="
              tableCol[i + 1]?.mergeDown
                ? tableCol[i + 1].rowspan
                  ? tableCol[i + 1].rowspan
                  : 2
                : 1
            "
            :rowspan="
              tableCol[i + 1]?.mergeRight
                ? tableCol[i + 1].colspan
                  ? tableCol[i + 1].colspan
                  : 2
                : 1
            "
            :class="{ deleteMe: tableCol[i + 1] === '*', 'alt-bg': i % 2 === 0 }"
            :style="{ fontSize: fontSizeChanger(i, j) + 'px' }"
          >
            <!-- {{ tableCol[i + 1] }} -->
            <!-- :class="{ 'idk-anymore': activeTab === 4 && j !== 3 && j !== 1 }" -->
            <div class="content">
              <div
                v-if="
                  typeof tableCol[i + 1] !== 'object' && String(tableCol[i + 1]).includes('png')
                "
              >
                <img :src="tableCol[i + 1]" alt="" />
              </div>
              <div v-else-if="tableCol[i + 1]?.mergeDown || tableCol[i + 1]?.mergeRight">
                <div v-if="typeof tableCol[i + 1].icon === 'object'" class="multiple-img">
                  <img v-for="(pic, l) of tableCol[i + 1].icon" :key="l" :src="pic" alt="" />
                </div>
                <img v-else-if="tableCol[i + 1]?.mergeDown" :src="tableCol[i + 1].icon" alt="" />
                {{
                  tableCol[i + 1]?.mergeDown
                    ? ''
                    : tableCol[i + 1]?.mergeRight
                    ? tableCol[i + 1].content
                    : tableCol[i + 1]
                }}
              </div>
              <div v-else-if="typeof tableCol[i + 1] === 'object'" class="content-content">
                <span v-for="(item, k) in tableCol[i + 1]" :key="k">
                  {{ item }}
                </span>
              </div>
              <span v-else>
                {{ tableCol[i + 1] }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped lang="scss">
.table-wrapper {
  max-width: 100%;
  overflow-x: scroll;

  table {
    border-spacing: 0;
    width: fit-content;
    tr {
      th {
        // height: 163px;
        min-width: 300px;
        text-align: center;
        font-size: 14px;
        font-weight: 500;

        a {
          height: 100%;
          img {
            height: 100%;
          }
        }
      }
      .skuter {
        height: 210px;
      }

      .cistac {
        height: 235px;
      }

      .roomba {
        height: 180px;
      }

      .sat {
        height: 220px;
      }

      .sluske {
        height: 280px;
      }

      // td:nth-child(even),
      // th:nth-child(even) {
      //   background: #f8f8f8;
      // }

      td {
        min-height: 145px;
        min-width: 300px;
        text-align: center;
        border-top: 1px solid #d8d8d8;
        color: #000;
        font-size: 34px;
        font-weight: 500;
        padding: 20px 25px;
        // white-space: pre;

        &:first-child {
          height: 120px;
          a {
            img {
              height: 100%;
            }
          }
        }

        .content {
          .content-content {
            display: flex;
            flex-direction: column;

            span:not(:last-child) {
              margin-bottom: 30px;
            }
          }

          .multiple-img {
            display: flex;
            align-items: center;
            justify-content: space-between;

            img:first-child {
              height: 80px;
            }

            img {
              height: 170px;
            }
          }
        }

        .idk-anymore {
          white-space: normal;
        }
        &:last-child:not(.disgust) {
          white-space: normal;
        }
        .disgust {
          white-space: pre;
        }
      }

      .header {
        font-size: 21px;
      }
    }
  }
}

.alt-bg {
  background: #f8f8f8;
}

@media only screen and (max-width: 748px) {
  .table-wrapper {
    table {
      tr {

        th {
          min-width: 190px;
        }
        
        td {
          font-size: 20px;
          min-width: 190px;
          min-height: 80px;
          padding: 10px 15px;

          .content {
            div {
              img {
                height: 80px;
              }
            }
          }
        }

        .header {
          font-size: 10px;
        }
        .skuter {
          height: 105px;
        }
      
        .cistac {
          height: 127px;
        }
      
        .roomba {
          height: 76px;
        }
      
        .sat {
          height: 86px;
        }
      
        .sluske {
          height: 140px;
        }
      }
    }
  }
}
</style>
