<script setup></script>
<template>
  <div class="footer-wrapper">
    <div class="socials-wrap">
      <span>Zapratite nas: </span>
      <a href="https://www.tiktok.com/@xiaomi.srbija" target="_blank" class="social">
        <img src="@/assets/images/tiktok.svg" alt="" />
      </a>
      <a href="https://www.facebook.com/XiaomiSrbija" target="_blank" class="social">
        <img src="@/assets/images/facebook.svg" alt="" />
      </a>
      <a href="https://www.instagram.com/xiaomi.srbija/" target="_blank" class="social">
        <img src="@/assets/images/instagram.svg" alt="" />
      </a>
      <a href="https://www.youtube.com/@xiaomi.srbija" target="_blank" class="social">
        <img src="@/assets/images/youtube.svg" alt="" />
      </a>
    </div>
    <hr />
    <div class="copyrights-wrap">
      <span>Copyright © 2023 Xiaomi. Sva prava zadržana.</span>
      <a href="https://privacy.mi.com/all/sr_RS/" target="_blank">Politika kolačića</a>
    </div>
  </div>
</template>
<style scoped lang="scss">
.footer-wrapper {
  width: 100%;
  height: 208px;
  padding: 25px 190px 28px;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;

  .socials-wrap {
    width: 100%;
    height: 33px;
    margin: 34px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      text-align: center;
      font-size: 24px;
      font-weight: 500;
    }

    .social {
      margin: 0 14.4px;
    }
  }

  hr {
    color: #bcbcbc;
  }

  .copyrights-wrap {
    display: flex;
    justify-content: space-between;

    span,
    a {
      font-size: 18px;
      font-weight: 400;
    }

    a {
      text-decoration: none;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 748px) {
  .footer-wrapper {
    padding: 25px 20px 28px;
    .socials-wrap {
      span {
        font-size: 14px;
      }

      .social {
      }
    }

    hr {
    }

    .copyrights-wrap {
      span,
      a {
        font-size: 14px;
      }

      a {
      }
    }
  }
}
</style>
