<script setup>
import tabButton from './tabButton.vue';
import tableComp from './tableComp.vue';
import images from '@/assets/js/images.js';
import { ref, computed, nextTick } from 'vue';

const activeTab = ref(0);
const forceUpdateKey = ref(0);

// this is the db
const avalableTables = [
  {
    tableHeaders: [
      'Motor',
      'Ugao\npenjanja',
      'Maksimalna\nbrzina',
      'Razdaljina\nputovanja',
      'Baterija',
      'Težina',
      'Maksimalno\nopterećenje\n(KG)',
      'Nivoi\nbrzine\n(svi modeli)',
      'Vreme\npunjenja',
      'IP-ocena'
    ],
    tableItems: [
      [
        // table row
        {
          //
          imgSrc: images.skuter1,
          link: 'https://mi-srbija.rs/lifestyle/trotineti/mi-elektricni-trotinet-essential',
        },
        '250 W',
        '10°',
        '20 km/h',
        '20 km',
        '5100 mAh',
        '12 kg',
        '100',
        {
          mergeDown: true,
          icon: images.skuterAdd1,
        },
        '3.5 sati',
        'IP54'
      ],
      [
        {
          imgSrc: images.skuter2,
          link: 'https://mi-srbija.rs/lifestyle/trotineti/mi-elektricni-trotinet-3-527',
        },
        '300 W',
        '16°',
        '25 km/h',
        '30 km',
        '7650 mAh',
        '13.2 kg',
        '100',
        '*',
        '5.5 sati',
        'IP54'
      ],
      [
        {
          imgSrc: images.skuter3,
          link: 'https://mi-srbija.rs/lifestyle/trotineti/xiaomi-elektricni-trotinet-4',
        },
        '300 W',
        '16°',
        '25 km/h',
        '35 km',
        '7650 mAh',
        '14.9 kg',
        '110',
        images.skuterAdd2,
        'otprilike\n5 sati',
        'IP54'
      ],
      [
        {
          imgSrc: images.skuter4,
          link: 'https://mi-srbija.rs/lifestyle/trotineti/xiaomi-elektricni-trotinet-4-pro',
        },
        '350 W',
        '20°',
        '25 km/h',
        '55 km',
        '12400 mAh',
        '17 kg',
        '120',
        {
          mergeDown: true,
          icon: images.skuterAdd3,
        },
        '8-9 sati',
        'IPX4'
      ],
      [
        {
          imgSrc: images.skuter5,
          link: 'https://mi-srbija.rs/lifestyle/trotineti/xiaomi-elektricni-trotinet-4-lite',
        },
        '300 W',
        '14°',
        '25 km/h',
        '20 km',
        '5200 mAh',
        '15.6 kg',
        '100',
        '*',
        'otprilike\n4.5 sati',
        'IP54'
      ]
    ],
  },
  {
    tableHeaders: [
      'Veličina\nsobe\n Na osnovu 2 ACPH',
      'CADR čestica',
      'Druge čestice\nkoje mogu prečistiti\n(svi modeli)',
      'Senzori',
      'Pametno\npovezivanje\n(svi modeli)',
      'Maksimalna\nizlazna\nsnaga',
      'Vek trajanja\nfiltera',
      'Dimenzije\nproizvoda'
    ],
    tableItems: [
      [
        {
          imgSrc: images.air1,
          link: 'https://mi-srbija.rs/smart-home/preciscivaci/xiaomi-preciscivac-vazduha-4-compact',
        },
        '48m²',
        '230m³/h',
        images.airAdd1,
        'PM2.5 IR senzor',
        {
          mergeDown: true,
          icon: images.airConnect1,
        },
        '27W',
        '6-12 meseci',
        'Φ220 x 355mm'
      ],
      [
        {
          imgSrc: images.air2,
          link: 'https://mi-srbija.rs/smart-home/preciscivaci/xiaomi-preciscivac-vazduha-4-lite',
        },
        '75m²',
        '360m³/h',
        images.airAdd2,
        ['PM2.5\nlaserski senzor', 'Senzor\ntemperature\ni vlažnosti'],
        '*',
        '33W',
        '6-12\nmeseci',
        '240 x 240 x 533.5mm'
      ],
      [
        {
          imgSrc: images.air3,
          link: 'https://mi-srbija.rs/smart-home/preciscivaci/xiaomi-preciscivac-vazduha-4',
        },
        '83m²',
        '400m³/h',
        images.airAdd3,
        ['PM2.5\nlaserski senzor', 'Senzor temperature\ni vlažnosti'],
        images.airConnect2,
        '30W',
        '6-12\nmeseci',
        '250 x 250 x 555mm'
      ],
      [
        {
          imgSrc: images.air4,
          link: 'https://mi-srbija.rs/smart-home/preciscivaci/xiaomi-preciscivac-vazduha-4-pro',
        },
        '104m²',
        '500m³/h',
        images.airAdd4,
        ['Senzor sa\ndvostrukim\nefektom\nPM2,5/PM10', 'Senzor temperature\ni vlažnosti'],
        {
          mergeDown: true,
          icon: images.airConnect3,
        },
        '50W',
        '6-12\nmeseci',
        '275 x 275 x 680mm'
      ],
      [
        {
          imgSrc: images.air5,
          link: 'https://mi-srbija.rs/smart-home/preciscivaci/xiaomi-preciscivac-vazduha-elite',
        },
        '125m²',
        '600m³/h',
        images.airAdd5,
        ['Senzor sa\ndvostrukim\nefektom\nPM2,5/PM10', 'Senzor temperature\ni vlažnosti'],
        '*',
        '60W',
        '10-14\nmeseci',
        'Φ310 × 730mm'
      ]
    ],
  },
  {
    tableHeaders: [
      'Snaga\nusisavanja',
      'Baterija',
      'Navigacioni\nsistem',
      'Pametno\npovezivanje\n(svi modeli)',
      'Funkcija\nbrisanja',
      'Automatska stanica\nza sakupljanje\nprašine',
      'Kutija\nza prašinu',
      'Rezerovar\nza vodu',
      'Izbegavanje\nprepreka',
      'Identifikacija\ntepiha',
      'Automatsko\ndopunjavanje',
      'Vek trajanja\nbaterije',
      'Senzori'
    ],
    tableItems: [
      [
        {
          imgSrc: images.roomba1,
          link: 'https://mi-srbija.rs/smart-home/usisivaci/xiaomi-robot-usisivac-mop-e10',
        },
        '4000 Pa',
        '2600 mAh',
        'žiroskop',
        {
          mergeDown: true,
          icon: images.roombaConnect1,
        },
        'da',
        'ne',
        {
          mergeRight: true,
          content:
            '2-u-1 kutija za prašinu i\nrezervoar za vodu, kutija za\nprašinu 400 ml, rezervoar\nza vodu 200 ml',
        },
        '*',
        'ne',
        'ne',
        'da',
        '110 minuta',
        'zidni senzor, senzor za dopunjavanje, senzor protiv sudara, senzor litice, 2-u-1 rezervoar za vodu i senzor za detekciju posude za prašinu, senzor za detekciju jastučića, senzor za detekciju crno-belih točkova, senzor povratne informacije o brzini točka'
      ],
      [
        {
          imgSrc: images.roomba2,
          link: 'https://mi-srbija.rs/smart-home/usisivaci/xiaomi-robot-usisivac-mop-s10',
        },
        '4000 Pa',
        '3200 mAh',
        'LDS',
        '*',
        'da',
        'ne',
        {
          mergeRight: true,
          content:
            '2-u-1 kutija za prašinu i \nezervoar za vodu, kutija za\nprašinu 300 ml, rezervoar\nza vodu 170 ml',
        },
        '*',
        'ne',
        'ne',
        'da',
        '130 minuta',
        'Senzor sudara, senzor litice, senzor brzine, indukcioni senzor, , žiroskop (IMU), radarski senzor, senzor za punjenje'
      ],
      [
        {
          imgSrc: images.roomba3,
          link: 'https://mi-srbija.rs/smart-home/usisivaci/xiaomi-robot-usisivac-mop-s10s',
        },
        '4000 Pa',
        '5200 mAh',
        'LDS',
        images.roombaConnect2,
        'da',
        'ne',
        '450 ml',
        '190 ml',
        'strukturisana\nsvetlost',
        'da',
        'da',
        '120 minuta',
        'Senzor pada, ultrazvučni senzor, LDS laserski senzor, linijski laserski senzor, AI vizuelni identifikacioni senzor, prošireni ivični senzor, IMU senzor, senzor podizanja pogonskog točka, senzor vazdušnog pritiska'
      ],
      [
        {
          imgSrc: images.roomba4,
          link: 'https://mi-srbija.rs/smart-home/usisivaci/xiaomi-robot-usisivac-mop-10-x10',
        },
        '4000 Pa',
        '5200 mAh',
        'LDS',
        {
          mergeDown: true,
          icon: images.roombaConnect3,
        },
        'da',
        'da',
        '400 ml',
        '200 ml',
        'ne',
        'ne',
        'da',
        '180 minuta',
        'Senzor pada, ultrazvučni senzor, LDS laserski senzor, linijski laserski senzor, AI vizuelni identifikacioni senzor, prošireni ivični senzor, detekcija statusa vakuumskog pokreta IMU Senzor Robot, senzor podizanja pogonskog točka, senzor vazdušnog pritiska'
      ],
      [
        {
          imgSrc: images.roomba5,
          link: 'https://mi-srbija.rs/smart-home/usisivaci/xiaomi-robot-usisivac-mop-x10',
        },
        '4000 Pa',
        '5200 mAh',
        'LDS',
        '*',
        'da',
        'da',
        '350 ml',
        '80 ml',
        'AI+\nstrukturisana\nsvetlost',
        'da',
        'da',
        '120 minuta',
        'Senzor pada, ultrazvučni senzor, LDS laserski senzor, linijski laserski senzor, AI vizuelni identifikacioni senzor, prošireni ivični senzor, detekcija statusa vakuumskog pokreta IMU Senzor Robot, senzor podizanja pogonskog točka, senzor vazdušnog pritiska'
      ]
    ],
  },
  {
    tableHeaders: [
      'Dimenzije',
      'Senzori',
      'Satelitski sistemi\nza pozicioniranje',
      'Baterija',
      'Ekran',
      'Mikrofon',
      'Zvučnik',
      'Otpornost\nna vodu',
      'Pametno\npovezivanje',
      'Režimi\nvežbanja',
      'Bluetooth\nglasovni pozivi',
      'Više funkcija'
    ],
    tableItems: [
      [
        {
          imgSrc: images.watch1,
          link: 'https://mi-srbija.rs/lifestyle/fitnes/redmi-watch-3',
        },
        '42.58 x 36.56 x 9.99mm',
        'Senzor otkucaja srca (sa senzorom kiseonika u krvi), akcelerometar, žiroskop, geomagnetni senzor',
        'Beidou, GPS,\nGLONASS, Galileo, QZSS',
        // 'Uobičajeno vreme upotrebe: 12 dana Kapacitet: 289mAh',
        'Do 12 dana, tipičan režim upotrebe\nKapacitet: 289mAh',
        // 'AMOLED ekran 1,75 inča osetljiv na dodir Rezolucija:  390×450 piksela Osvetljenost: do 600 nita, podesiva Staklo sa premazom protiv otisaka prstiju',
        '1,75" AMOLED ekran\nRezolucija: 390×450 piksela',
        'da',
        'da',
        '5ATM vodootporan',
        '',
        '120+',
        'da',
        // 'Praćenje kiseonika u krvi, praćenje otkucaja srca, praćenje spavanja, praćenje stresa, vežbe disanja'
        'Praćenje stresa, vežbe disanja, praćenja sna'
      ],
      [
        {
          imgSrc: images.watch2,
          link: 'https://mi-srbija.rs/lifestyle/fitnes/xiaomi-watch-s1-pro',
        },
        '46mm x 46mm x 11.28mm',
        'Senzor otkucaja srca (sa senzorom kiseonika u krvi), akcelerometar, žiroskop, geomagnetni senzor, atmosferski senzor, senzor ambijentalnog svetla, senzor poklopca, senzor temperature',
        'Beidou, GPS,\nGLONASS, Galileo, QZSS',
        // 'Tip punjenja: bežično punjenje Vreme punjenja: ≤85 min Tipično vreme upotrebe: 14 dana Kapacitet: 500mAh',
        'Do  14 dana, tipičan režim upotrebe\nKapacitet: 500mAh',
        // 'AMOLED ekran 1,47 inča osetljiv na dodir Rezolucija: 480 k 480 piksela Osvetljenost: do 600 nita, podesiva Safirno staklo sa  premazom protiv  otisaka prstiju',
        '1,47" AMOLED ekran\nRezolucija: 480 k 480 piksela',
        'da',
        'da',
        '5ATM vodootporan',
        {
          mergeDown: true,
          rowspan: 3,
          icon: [images.watchConnect1, images.watchConnect2],
        },
        '100+',
        'da',
        // 'NFC bezgotovinsko plaćanje, praćenje otkucaja srca, praćenje spavanja, praćenje stresa, praćenje kiseonika u krvi, 10 ugrađenih kurseva trčanja, precizno 3D praćenje udaljenosti za planinarenje, obrnuto bežično punjenje'
        'NFC bezgotovinsko plaćanje, precizno 3D praćenje udaljenosti za planinarenje, obrnuto bežično punjenje, praćenje stresa, vežbe disanja, praćenja sna'
      ],
      [
        {
          imgSrc: images.watch3,
          link: 'https://mi-srbija.rs/lifestyle/fitnes/xiaomi-smart-band-7-pro',
        },
        '44.7mm × 28.8mm × 11mm',
        'Senzor otkucaja srca (sa senzorom kiseonika u krvi), akcelerometar, žiroskop, senzor ambijentalnog svetla',
        'Beidou, GPS,\nGLONASS, Galileo, QZSS',
        // 'Tip punjenja: magnetno punjenje Vreme punjenja: približno 60 minuta Tipično vreme upotrebe: ≥12 dana Kapacitet: 235mAh',
        'Do 12 dana, tipičan režim upotrebe\nKapacitet: 235mAh',
        // ' AMOLED ekran 1,64 inča osetljiv na dodir Rezolucija: 280 k 456 piksela, 326 PPI Osvetljenost: do 500 nita, podesiva Kaljeno staklo sa premazom protiv otisaka prstiju | 2.5D mikro zakrivljeno staklo',
        '1,64" AMOLED ekran\nRezolucija: 280 k 456 piksela',
        'da',
        'ne',
        '5ATM vodootporan',
        '*',
        '110+',
        'ne',
        // 'Reprodukcija muzike, statistika, zapis o vežbanju, vežbanje, događaji, ne ometaj, alarm, tajmer, vreme, baterijska lampa, praćenje otkucaja srca, praćenje SpO₂, praćenje spavanja, praćenje ženskog zdravlja, praćenje stresa, vežbe disanja'
        'Reprodukcija muzike, statistika, zapis o vežbanju, alarm, tajmer, vreme, baterijska lampa,  praćenje ženskog ciklusa, praćenje stresa, vežbe disanja, praćenja sna'
      ],
      [
        {
          imgSrc: images.watch4,
          link: 'https://mi-srbija.rs/lifestyle/fitnes/xiaomi-watch-s1-676',
        },
        // '46.5mm x 46.5mm x 11mm\n(ne uključujući narukvicu\ni lokalne izbočine)',
        '46.5mm x 46.5mm x 11mm',
        'Senzor otkucaja srca (sa senzorom kiseonika u krvi), akcelerometar, žiroskop, geomagnetni senzor, atmosferski senzor, senzor ambijentalnog svetla',
        'BeiDou, GPS,\nGLONASS, Galileo, QZSS',
        // '470 mAh 12 dana Tipičan režim korišćenja 124 dana Režim uštede energije',
        'Do 12 dana, tipičan režim upotrebe\nDo 24 dana, režim uštede baterije\nKapacitet: 470 mAh',
        // '1,43"" AMOLED ekran 326ppi Visoka rezolucija Safirno staklo Kućište od nerđajućeg čelika',
        '1,43"" AMOLED ekran\nRezolucija 466 x 466 piksela',
        'da',
        'da',
        '5ATM vodootporan',
        '*',
        '117',
        'da',
        // 'NFC bezgotovinsko plaćanje, GPS sa dva opsega, bežično punjenje, praćenje otkucaja srca, praćenje SpO₂, praćenje spavanja, praćenje ženskog zdravlja, praćenje stresa, vežbe disanja'
        'NFC bezgotovinsko plaćanje, GPS sa dva opsega, bežično punjenje praćenje ženskog zdravlja, praćenje stresa, vežbe disanja, praćenja sna'
      ],
      [
        {
          imgSrc: images.watch5,
          link: 'https://mi-srbija.rs/lifestyle/fitnes/xiaomi-watch-s1-active-675',
        },
        // '46.5 x 47.3 x 11mm\n(ne uključujući narukvicu\ni lokalne izbočine)',
        '46.5 x 47.3 x 11mm',
        'Senzor otkucaja srca (sa senzorom kiseonika u krvi), akcelerometar, žiroskop, geomagnetni senzor, atmosferski senzor, senzor ambijentalnog svetla',
        'GPS, GLONASS,\nGALILEO, BDS, QZSS',
        // '470 mAh 12 dana Tipičan režim korišćenja 24 dana Režim uštede baterije 30 sati GPS režima',
        'Do 12 dana, tipičan režim upotrebe\nDo 24 dana, režim uštede baterije\n Do 30 sati GPS režima\nKapacitet: 470 mAh',
        '1,43"" AMOLED ekran Rezolucija 466x466',
        'da',
        'da',
        '5ATM vodootporan',
        '',
        '117',
        'ne',
        // 'NFC bezgotovinsko plaćanje, GPS sa više sistema sa dva opsega, praćenje SpO₂, praćenje spavanja, praćenje ženskog zdravlja, praćenje stresa, vežbe disanja'
        'NFC bezgotovinsko plaćanje, GPS sa više sistema sa dva opsega, praćenje SpO₂, praćenje sna, praćenje ženskog ciklusa, praćenje stresa, vežbe disanja'
      ]
    ],
  },
  {
    tableHeaders: [
      'Trajanje\nbaterije\n(pozivi)',
      'Broj povezanih\nuređaja',
      'Google\nFast Pair',
      'Način\nkontrole',
      'Detekcija\nu uhu',
      'Poništavanje\nbuke',
      'Vodootpornost',
      'Funckije',
      'Domet'
    ],
    tableItems: [
      [
        {
          imgSrc: images.earphones1,
          link: 'https://mi-srbija.rs/audio/slusalice/xiaomi-buds-4-pro',
        },
        [
          'Do 9 sati slušanja –\ntrajanje baterije sa\njednim punjenjem',
          'Do 38 sati korišćenja -\nTrajanje baterije sa\nfutrolom za punjenje',
          'Do 3 sata reprodukcije\nmuzike - Nakon 5\nminuta punjenja u\nkućištu za punjenje'
        ],
        '2',
        'da',
        'dodirom',
        'da',
        'da',
        'IP54',
        'režim transparentnosti,\nautomatsko\nuključivanje/isključivanje\ni uparivanje,\nkompatibilnost sa KI\nsertifikovanim\npunjačima, podrška za\nAAC i SBC tehnologiju',
        '10m (otvoreni prostor\nbez prepreka)'
      ],
      [
        {
          imgSrc: images.earphones2,
          link: 'https://mi-srbija.rs/audio/slusalice/redmi-buds-4-lite',
        },
        [
          'Do 5 sati - Trajanje\nbaterije sa jednim\npunjenjem',
          'Do 20 sati - Koristi se u\nkombinaciji sa futrolom\nza punjenje'
        ],
        '1',
        'da',
        'dodirom',
        'da',
        'da',
        'IP54',
        'AI poništavanje\nbuke',
        '10m (otvoreni prostor\nbez prepreka)'
      ],
      [
        {
          imgSrc: images.earphones3,
          link: 'https://mi-srbija.rs/audio/slusalice/redmi-buds-4-pro',
        },
        ['9h + 36h\n(ANC isključen)', '5h + 25h\n(ANC uključen)'],
        '2',
        'da',
        'dodirom',
        'da',
        'da',
        'IPX4',
        'Dvostruki režim\ntransparentnosti, AI\nadaptivni ANC,\ninovativno smanjenje\nbuke vetra',
        '10m (otvoreni prostor\nbez prepreka)'
      ],
      [
        {
          imgSrc: images.earphones4,
          link: 'https://mi-srbija.rs/audio/slusalice/redmi-buds-4',
        },
        [
          'Do 6 sati trajanja\nbaterije po punjenju',
          'Do 30 sati ako se koristi\nsa kućištem za punjenje'
        ],
        '1',
        'da',
        'dodirom',
        // 'da',
        'ne',
        'da',
        'IPX4',
        'režim transparentnosti,\nautomatsko\nuključivanje/isključivanje\ni uparivanje, podrška za\nAAC tehnologiju,\nadaptivni ANC režim',
        '10m (otvoreni prostor\nbez prepreka)'
      ]
    ],
  }
];

const availableTabs = [
  {
    title: 'Skuteri',
    subtitle:
      'Istražite neverovatne Xiaomi trotinete - brzi, praktični i moderni! Naši trotineti omogućavaju vam da se brzo krećete gradom, bez gužve i stresa. Njihova moderna tehnologija i izdržljiva konstrukcija obezbeđuju sigurnost i udobnost tokom vožnje. Takođe, imate mogućnost da istražite grad na potpuno nov način. Pogledajte našu ponudu i pronađite savršen model koji odgovara vašem stilu i potrebama kako biste uživali u zabavnoj vožnji uz Xiaomi trotinete!',
    table: avalableTables[0],
  },
  {
    title: 'Prečišćivači',
    subtitle:
      'Xiaomi prečišćivači vazduha su poznati po izvanrednim performansama! Ovi uređaji koriste napredne tehnologije filtracije kako bi efikasno uklonili zagađivače, alergene i neprijatne mirise iz vazduha, kao i druge čestice, omogućavajući zdrav vazduh u vašem domu. Zahvaljujući svom dizajnu, Xiaomi prečišćivači će se lako uklopiti u svaku prostoriju. Takođe, sa funkcijama poput CADR-a (stopa prečišćenog vazduha), mogućnosti pametne kontrole i praćenja kvaliteta vazduha u realnom vremenu, ovi uređaji su i praktični za korišćenje, posebno zbog intuitivnog interfejsa. Pogledajte naše prečišćivače u nastavku!',
    table: avalableTables[1],
  },
  {
    title: 'Usisivači',
    subtitle:
      'Otkrijte revolucionarne Xiaomi robot usisivače! Zahvaljujući naprednoj tehnologiji i preciznom senzorskom sistemu, ovi roboti usisivači čiste svaki kutak vašeg doma. Takođe, putem Mi Home aplikacije možete pratiti čišćenje u realnom vremenu - Xiaomi robot usisivači postavljaju novi standard za čistoću vašeg doma. Izaberite model Xiaomi robot usisivača koji najbolje odgovara vašim potrebama i transformišite način čišćenja!',
    table: avalableTables[2],
  },
  {
    title: 'Satovi',
    subtitle:
      'Otkrijte budućnost pametnih satova i narukica uz Xiaomi uređaje. Unapredite svoj stil i život ovim najsavremenijim uređajima. Budite u toku, pratite svoje zdravlje, ostvarite fitnes ciljeve, primajte obaveštenja u realnom vremenu - sve to dok izražavate svoj jedinstveni modni stil. Sa raznovrsnom ponudom pametnih satova i narukvica, Xiaomi ima uređaj koji će uspeti da prati svaki vaš korak. Doživite besprekorno spajanje stila i tehnologije - istražite Xiaomi pametne satove i narukvice!',
    table: avalableTables[3],
  },
  {
    title: 'Slušalice',
    subtitle:
      'Xiaomi i Redmi bežične slušalice - spoj stila, kvaliteta zvuka i udobnosti. Uživajte u vrhunskom zvuku i intuitivnim kontrolama. Istražite više o ovim vrhunskim slušalicama i doživite zvuk na potpuno novi način!',
    table: avalableTables[4],
  }
];

const setActiveTab = (index) => {
  if (activeTab.value === 0 || activeTab.value !== index) {
    activeTab.value = index;
    nextTick(() => {
      document
        .getElementById('tabcontentwaypoint')
        .scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    });
  } else activeTab.value = 0;
  forceUpdateKey.value++;
};

const getActiveTitle = computed(() => {
  return availableTabs[activeTab.value - 1].title;
});

const getActiveInfo = computed(() => {
  return availableTabs[activeTab.value - 1].subtitle;
});
</script>
<template>
  <div class="handler-wrapper">
    <div class="table-controller-wrap">
      <tabButton
        :img-src="images.scooterImg"
        :img-active-src="images.scooterImgActive"
        title="Skuteri"
        :is-active="activeTab === 1"
        @set-active="setActiveTab(1)"
      />
      <tabButton
        :img-src="images.airFilterImg"
        :img-active-src="images.airFilterImgActive"
        title="Prečišćivači"
        :is-active="activeTab === 2"
        @set-active="setActiveTab(2)"
      />
      <tabButton
        :img-src="images.vacuumImg"
        :img-active-src="images.vacuumImgActive"
        title="Usisivači"
        :is-active="activeTab === 3"
        @set-active="setActiveTab(3)"
      />
      <tabButton
        :img-src="images.watchImg"
        :img-active-src="images.watchImgActive"
        title="Satovi"
        :is-active="activeTab === 4"
        @set-active="setActiveTab(4)"
      />
      <tabButton
        :img-src="images.earphonesImg"
        :img-active-src="images.earphonesImgActive"
        title="Slušalice"
        :is-active="activeTab === 5"
        @set-active="setActiveTab(5)"
      />
    </div>

    <div id="tabcontentwaypoint"></div>
    <transition name="slide-fade">
      <div v-if="activeTab !== 0" class="tab-content">
        <div class="hr-wrap">
          <hr />
        </div>
        <div class="info-wrap">
          <div class="info-title">{{ getActiveTitle }}</div>
          <div class="info-subtitle">{{ getActiveInfo }}</div>
        </div>
      </div>
    </transition>

    <div class="table-wrap">
      <tableComp
        v-if="activeTab !== 0"
        :key="forceUpdateKey"
        :active-tab="activeTab"
        :table-headers="availableTabs[activeTab - 1].table.tableHeaders"
        :table-rows="availableTabs[activeTab - 1].table.tableItems"
      />
    </div>
  </div>
</template>
<style scoped lang="scss">
.handler-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .table-controller-wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 85%;
    padding: 0 190px;
    margin-bottom: 60px;
    gap: 10px;
  }

  .tab-content {
    padding: 0 190px;

    .hr-wrap {
      hr {
        color: #bcbcbc;
      }
    }

    .info-wrap {
      margin-top: 100px;

      .info-title {
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 25px;
      }

      .info-subtitle {
        font-size: 24px;
        font-weight: 400;
        margin-bottom: 40px;
      }
    }
  }

  .table-wrap {
    width: 100%;
    padding: 0 190px;
  }
}

.slide-fade-enter-active {
  transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

@media only screen and (max-width: 748px) {
  .handler-wrapper {
    .table-controller-wrap {
      width: 100%;
      padding: 0 54px;
    }

    .tab-content {
      padding: 0 40px;

      .hr-wrap {
        padding: 0 14px;
      }

      .info-wrap {
        margin-top: 50px;

        .info-title {
          font-size: 36px;
          margin-bottom: 35px;
          text-align: center;
        }

        .info-subtitle {
          font-size: 24px;
          margin-bottom: 40px;
          text-align: center;
        }
      }
    }

    .table-wrap {
      padding: 0;
    }
  }
}

@media only screen and (max-width: 480px) {
  .handler-wrapper {
    .table-controller-wrap {
      padding: 0 20px;
    }

    .tab-content {
      padding: 0 20px;

      .hr-wrap {
        padding: 0 14px;
      }

      .info-wrap {
        margin-top: 50px;

        .info-title {
          font-size: 24px;
          margin-bottom: 35px;
          text-align: center;
        }

        .info-subtitle {
          font-size: 16px;
          line-height: 1.3;
          margin-bottom: 40px;
          text-align: center;
        }
      }
    }

    .table-wrap {
      padding: 0;
    }
  }
}
</style>
