<script setup>
import {computed} from 'vue';

const props = defineProps({
  imgSrc: String,
  imgActiveSrc: String,
  title: String,
  isActive: Boolean,
});

const emit = defineEmits(['setActive']);

const handleStateChange = computed(() => {
  return !props.isActive ? props.imgSrc : props.imgActiveSrc;
});

const handleClick = () => {
  emit('setActive');
};
</script>
<template>
  <div class="tab-button-wrapper" @click="handleClick">
    <div class="img-wrap">
        <img :src="handleStateChange" alt="" />
        <!-- <img v-else :src="imgActiveSrc" alt="" /> -->
    </div>
    <div class="title-wrap">
      {{ title }}
    </div>
  </div>
</template>
<style scoped lang="scss">
.tab-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .title-wrap {
    color: #bcbcbc;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


@media only screen and (max-width: 748px) {
  .tab-button-wrapper {
    .img-wrap {
      width: 83px;

      img {
        width: 100%;
      }
    }
    .title-wrap {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .tab-button-wrapper {
    .img-wrap {
      width: 60px;
    }
    // .title-wrap {
    //   font-size: 12px;
    // }
  }
}
  
</style>
