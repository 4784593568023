import scooterImg from '@/assets/images/icons/skutericon.png';
import scooterImgActive from '@/assets/images/icons/skutericonactive.png';
import watchImg from '@/assets/images/icons/satoviicon.png';
import watchImgActive from '@/assets/images/icons/satoviiconactive.png';
import earphonesImg from '@/assets/images/icons/slusaliceicon.png';
import earphonesImgActive from '@/assets/images/icons/slusaliceiconactive.png';
import vacuumImg from '@/assets/images/icons/usisivaciicon.png';
import vacuumImgActive from '@/assets/images/icons/usisivaciiconactive.png';
import airFilterImg from '@/assets/images/icons/preciscivaciicon.png';
import airFilterImgActive from '@/assets/images/icons/preciscivaciiconactive.png';

import skuter1 from '@/assets/images/tableImages/skuter1.png';
import skuter2 from '@/assets/images/tableImages/skuter2.png';
import skuter3 from '@/assets/images/tableImages/skuter3.png';
import skuter4 from '@/assets/images/tableImages/skuter4.png';
import skuter5 from '@/assets/images/tableImages/skuter5.png';
import skuterAdd1 from '@/assets/images/tableImages/skuterAdd1.png';
import skuterAdd2 from '@/assets/images/tableImages/skuterAdd2.png';
import skuterAdd3 from '@/assets/images/tableImages/skuterAdd3.png';

import air1 from '@/assets/images/tableImages/air1.png';
import air2 from '@/assets/images/tableImages/air2.png';
import air3 from '@/assets/images/tableImages/air3.png';
import air4 from '@/assets/images/tableImages/air4.png';
import air5 from '@/assets/images/tableImages/air5.png';
import airAdd1 from '@/assets/images/tableImages/airAdd1.png';
import airAdd2 from '@/assets/images/tableImages/airAdd2.png';
import airAdd3 from '@/assets/images/tableImages/airAdd3.png';
import airAdd4 from '@/assets/images/tableImages/airAdd4.png';
import airAdd5 from '@/assets/images/tableImages/airAdd5.png';
import airConnect1 from '@/assets/images/tableImages/airConnect1.png';
import airConnect2 from '@/assets/images/tableImages/airConnect2.png';
import airConnect3 from '@/assets/images/tableImages/airConnect3.png';

import roomba1 from '@/assets/images/tableImages/roomba1.png';
import roomba2 from '@/assets/images/tableImages/roomba2.png';
import roomba3 from '@/assets/images/tableImages/roomba3.png';
import roomba4 from '@/assets/images/tableImages/roomba4.png';
import roomba5 from '@/assets/images/tableImages/roomba5.png';
import roombaConnect1 from '@/assets/images/tableImages/roombaConnect1.png';
import roombaConnect2 from '@/assets/images/tableImages/roombaConnect2.png';
import roombaConnect3 from '@/assets/images/tableImages/roombaConnect3.png';

import watch1 from '@/assets/images/tableImages/watch1.png';
import watch2 from '@/assets/images/tableImages/watch2.png';
import watch3 from '@/assets/images/tableImages/watch3.png';
import watch4 from '@/assets/images/tableImages/watch4.png';
import watch5 from '@/assets/images/tableImages/watch5.png';
import watchConnect1 from '@/assets/images/tableImages/watchConnect1.png';
import watchConnect2 from '@/assets/images/tableImages/watchConnect2.png';

import earphones1 from '@/assets/images/tableImages/earphones1.png';
import earphones2 from '@/assets/images/tableImages/earphones2.png';
import earphones3 from '@/assets/images/tableImages/earphones3.png';
import earphones4 from '@/assets/images/tableImages/earphones4.png';

export default {
  scooterImg,
  scooterImgActive,
  watchImg,
  watchImgActive,
  earphonesImg,
  earphonesImgActive,
  vacuumImg,
  vacuumImgActive,
  airFilterImg,
  airFilterImgActive,
  skuter1,
  skuter2,
  skuter3,
  skuter4,
  skuter5,
  skuterAdd1,
  skuterAdd2,
  skuterAdd3,
  air1,
  air2,
  air3,
  air4,
  air5,
  airAdd1,
  airAdd2,
  airAdd3,
  airAdd4,
  airAdd5,
  airConnect1,
  airConnect2,
  airConnect3,
  roomba1,
  roomba2,
  roomba3,
  roomba4,
  roomba5,
  roombaConnect1,
  roombaConnect2,
  roombaConnect3,
  watch1,
  watch2,
  watch3,
  watch4,
  watch5,
  watchConnect1,
  watchConnect2,
  earphones1,
  earphones2,
  earphones3,
  earphones4
}